<template>
    <div>
        <template
            v-for="thread in threads"
            :key="thread.id"
        >
            <UiButtonModal
                :id="`chat-modal-thread-${thread.id}`"
                no-background
            >
                <template #trigger>
                    <chat-thread-preview
                        :thread="thread"
                    />
                </template>
                <chat-thread
                    :thread="thread"
                    class="max-h-[70vh] rounded-md"
                />
            </UiButtonModal>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        threads: {
            type: Array,
            required: true
        }
    }
}
</script>
