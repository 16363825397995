<template>
    <UiButtonSlideOver
        id="messenger"
        ref="messageSlideOver"
        no-padding
        @closed="fetchThreads"
        @opened="fetchThreads"
    >
        <template #trigger>
            <div class="h-16 flex items-center">
                <button
                    type="button"
                    class="text-gray-400 relative transition-colors hover:text-gray-500 group"
                >
                    <span class="sr-only">Messages</span>
                    <ChatBubbleOvalLeftEllipsisIconOutline class="size-6" />
                    <span
                        v-if="unreadThreads"
                        class="absolute z-10 flex items-center justify-center w-5 h-5 text-white rounded-full -top-3 -right-3 bg-primary-600"
                        style="font-size: 11px; line-height: 8px;"
                    >
                        <span class="group-hover:animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-600 opacity-0 group-hover:opacity-45" />
                        {{ unreadThreads > 9 ? '9+' : unreadThreads }}
                    </span>
                </button>
            </div>
        </template>

        <div class="flex flex-col h-[calc(100vh-50px)] max-w-full max-h-full">
            <div class="flex pb-6 px-6 items-center justify-between">
                <div class="subtitle">
                    Discussions
                </div>
                <button
                    class="btn btn-default text-gray-700 btn-icon rounded-full shadow-none border-0"
                    @click="openCreatePage"
                >
                    <PencilSquareIconMini class="size-5" />
                </button>
            </div>
            <div class="grow px-3 max-w-full overflow-auto">
                <chat-threads-list
                    :threads="threads"
                />
                <button
                    v-if="pagination.current_page < pagination.last_page"
                    :class="{ 'loading gray': isLoading }"
                    class="w-full justify-center mt-2 mb-4 btn btn-link"
                    @click="loadMore"
                >
                    Voir plus ...
                </button>
            </div>
        </div>
    </UiButtonSlideOver>
</template>

<script>
import { loadingMixin } from 'abcreche-ui'
import ChatRepository from '~/repositories/Shared/ChatRepository.js'

export default {
    mixins: [loadingMixin],

    data () {
        return {
            threads: [],
            unreadThreads: 0,
            page: 1,
            pagination: {},
            selectedThread: null
        }
    },

    mounted () {
        if (this.$user) {
            this.$echo.private('messages.user.' + this.$user.uuid)
                .on('message.created', (e) => {
                    this.fetchThreads()
                })
        }
    },

    created () {
        this.fetchThreads()

        this.$emitter.on('chat-open-parent', ({ parent }) => {
            this.$refs.messageSlideOver.openSlideOver()
            this.selectedParent = parent

            ChatRepository.fetchThreads({ email: this.selectedParent ? this.selectedParent.user.email : null })
                .then((response) => {
                    if (response.data.length) {
                        this.selectThread(response.data[0])
                    }
                })
                .catch(error => this.pageError(error))
        })
    },

    methods: {
        fetchThreads (append = false) {
            ChatRepository.fetchThreads({ page: this.page })
                .then((response) => {
                    this.threads = append
                        ? [
                                ...this.threads,
                                ...response.data
                            ]
                        : response.data

                    this.pagination = response.meta
                    this.unreadThreads = response.total_unread
                    this.done()
                })
                .catch(error => this.pageError(error))
        },

        selectThread (thread) {
            this.selectedThread = thread
        },

        openCreatePage () {
            this.$router.push(this.layoutPrefix + '/messages/create')
            this.$slideOver?.closeAll()
        },

        loadMore () {
            this.load()
            this.page++
            this.fetchThreads(true)
        }
    }
}
</script>
