<template>
    <div>
        <UiSectionTitle class="border-none">
            Besoin d'aide ?
        </UiSectionTitle>
        <div class="grid grid-cols-2 gap-6">
            <div class="p-4 rounded-lg border border-gray-200 shadow lg:p-8">
                <h3 class="mb-4 text-xl font-extrabold">
                    Questions sur le logiciel ?
                </h3>
                <ul class="mb-4 space-y-6 text-primary-600">
                    <li>
                        <a
                            href="https://docs.abcreche.com"
                            target="_blank"
                            class="hover:underline font-medium"
                        >Consulter la documentation
                            <ArrowTopRightOnSquareIconMicro class="size-4 inline" />
                        </a>
                        <div class="prose mt-2 prose-sm">
                            <p>
                                Identifiant : ABCreche
                                <br>
                                Mot de passe : 16f3tqwdKg13S28Te10r
                            </p>
                        </div>
                    </li>
                    <li>
                        <a
                            href="https://communaute.abcreche.com/"
                            class="hover:underline font-medium"
                        >Consulter le forum
                            <ArrowTopRightOnSquareIconMicro class="size-4 inline" />
                        </a>

                        <div class="prose mt-2 prose-sm">
                            <p>Le forum est un bon endroit pour:</p>
                            <ul>
                                <li>poser des questions sur le fonctionnement du logiciel</li>
                                <li>partager des astuces</li>
                                <li>soumettre des idées d'amélioration</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="p-4 rounded-lg border border-gray-200 shadow lg:p-8">
                <h3 class="mb-4 text-xl font-extrabold">
                    Un bug ?
                </h3>

                <div class="prose prose-a:text-primary-600 prose-a:no-underline prose-sm">
                    <p>
                        <strong>Envoyez un email sur <a
                            href="mailto:support@abcreche.com"
                            class="hover:underline"
                        >support@abcreche.com</a> :</strong>
                    </p>
                    <ul>
                        <li>dans le cas où vous rencontrez un bug</li>
                        <li>si vous un souci et que vous devez communiquer des détails (noms d’enfants, etc)</li>
                        <li>s’il y a un problème à résoudre</li>
                    </ul>

                    <p>
                        Lorsque vous soumettez un bug, veuillez inclure un maximum d'informations telles que le nom de la crèche, numéro de dossier de l'enfant, captures d'écrans, procédures pour répliquer le souci, etc.
                    </p>
                    <p>
                        Nous vous contacterons nous même par téléphone si besoin.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
</script>
